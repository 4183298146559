<template>
  <body style="font-size: 12px">
    <noscript
      ><strong
        >We're sorry but 核酸检测结果查询 doesn't work properly without
        JavaScript enabled. Please enable it to continue.</strong
      ></noscript
    >
    <div class="app">
      <!---->
      <div class="app-container">
        <div class="layout-content">
          <div data-v-6fecc44e="" class="index-container">
            <div data-v-6fecc44e="">
              <div data-v-6fecc44e="" class="header">
                <img
                  data-v-6fecc44e=""
                  src="static/img/virus-banner.361329c1.jpg"
                  alt=""
                />
              </div>
              <div data-v-6fecc44e="" class="content">
                <form data-v-6fecc44e="" class="van-form">
                  <div data-v-6fecc44e="" class="form">
                    <div data-v-6fecc44e="" class="input van-cell van-field">
                      <div class="van-cell__title van-field__label">
                        <span>姓名:</span>
                      </div>
                      <div class="van-cell__value van-field__value">
                        <div class="van-field__body">
                          <input
                            type="text"
                            name="name"
                            placeholder="请输入姓名"
                            class="van-field__control van-field__control--right"
                          />
                        </div>
                      </div>
                    </div>
                    <div data-v-6fecc44e="" class="input van-cell van-field">
                      <div class="van-cell__title van-field__label">
                        <span>证件号:</span>
                      </div>
                      <div class="van-cell__value van-field__value">
                        <div class="van-field__body">
                          <input
                            type="text"
                            name="idcode"
                            placeholder="请输入检测人证件号"
                            class="van-field__control van-field__control--right"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-v-6fecc44e="" class="submit-btn">
                    <button
                      data-v-6fecc44e=""
                      type="submit"
                      class="
                        button
                        van-button
                        van-button--info
                        van-button--normal
                        van-button--block
                        van-button--round
                      "
                    >
                      <div data-v-6fecc44e="" class="van-button__content">
                        <span data-v-6fecc44e="" class="van-button__text">
                          查询
                        </span>
                      </div>
                    </button>
                  </div>
                </form>
                <div data-v-6fecc44e="" class="notice">
                  <h3 data-v-6fecc44e="" class="title">
                    温馨提示 <span data-v-6fecc44e="" class="h1-border"></span>
                  </h3>
                  <p data-v-6fecc44e="" class="item">
                    1、支持查询最近5次核酸检测结果；
                  </p>
                  <p data-v-6fecc44e="" class="item">
                    2、支付宝、微信每日各可进行10次查询（包含扫码）；
                  </p>
                  <p data-v-6fecc44e="" class="item">
                    3、核酸检测结果仅用于健康码亮码或出行参考，违规查询他人信息保留追究责任。
                  </p>
                </div>
              </div>
            </div>
            <div data-v-6fecc44e="" class="logo">
              <img data-v-6fecc44e="" src="static/img/ic_logo.db4c7908.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
export default {};
// function getBizScenario() {
//   var sUserAgent = navigator.userAgent.toLowerCase();
//   var bIsApp = sUserAgent.indexOf("izzzwfwapp") > -1;
//   var bIsAlipay = sUserAgent.indexOf("alipayclient") > -1;
//   var isWx = sUserAgent.indexOf("micromessenger") > -1;
//   if (bIsApp) {
//     return "zhb";
//   } else if (bIsAlipay) {
//     return "alipay";
//   } else if (isWx) {
//     return "wechart";
//   } else {
//     return "other";
//   }
// }
</script>

<style></style>
